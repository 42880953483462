import React from 'react';
import {
  View,
  Text,
  Button,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Keyboard,
  Image,
  Platform,
  Dimensions,
} from 'react-native';
import {
  GiftedChat,
  Send,
  Actions,
  ActionsProps,
  Bubble,
  InputToolbar,
  LoadEarlier,
  Day,
} from 'react-native-gifted-chat';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import 'dayjs/locale/ja';
import axios from 'axios';
import ActionCable from 'react-native-actioncable';
import * as SecureStore from 'expo-secure-store';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';
import Constants from 'expo-constants';

import InstructionUtils from '../utils/InstructionUtils';
import ImageUtils from '../utils/ImageUtils';
import StatusUtils from '../utils/StatusUtils';
import { AuthContext } from '../utils/ContextUtils';

export default class Chat extends React.Component {

  
  // export default class Chat extends React.PureComponent {
  constructor(props) {
    console.log('==================== Chat.constructor start.');
    super(props);
    this.state = {
      instructions: [],
      instructionStatuses: [],
      messages: [],
      selectedImg: null,
      memberId: 0,
      manager: true,
      limit: 20,
      offset: 0,
      isLoadingEarlier: false,
      statusList: [
        {
          id: 1,
          name: "レポート",
        },
        {
          id: 2,
          name: "育成通知",
        },
        // {
        //   id: 3,
        //   name: "運営からのお知らせ",
        // },
      ]
    };
    this.channel = null;
    console.log('==================== Chat.constructor end.');
  }

  componentDidMount() {
    console.log('==================== Chat.componentDidMount start.');
    console.log(this.context.user.id);
    this.setState({
      memberId: this.context.user.id,
      manager: this.context.user.role <= 2 ? true : false, // 契約者以上（契約者、管理者、システム管理者）は管理者用モード
      instructions: this.props.navigation.state.params.instructions,
      instructionStatuses:
        this.props.navigation.state.params.instructionStatuses,
    });
    this.createSocket();
    this.patchAccessStatus();
    this.getMessages();
    console.log('==================== Chat.componentDidMount end.');
  }

  componentWillUnmount() {
    console.log('==================== Chat.componentWillUnmount start.');
    this.closeSocket();
    console.log('==================== Chat.componentWillUnmount end.');
  }

  /////////////////////////////////////////////////////////////////////////
  // Action Cable (WebSocket)
  /////////////////////////////////////////////////////////////////////////
  createSocket() {
    console.log('==================== Chat.createSocket start.');
    this.context.cable = ActionCable.createConsumer(
      Constants.manifest.extra.actionCableUrl + '/cable'
    );
    console.log("通った１");
    this.channel = this.context.cable.subscriptions.create(
      {
        //channel: 'chat_channel_' + this.props.navigation.state.params.chat_id
        channel: 'ChatChannel',
        chat_id: this.props.navigation.state.params.chat_id,
      },
      {
        connected: () => {
          console.log('Connected!');
        },
        disconnected: () => {
          console.log('Disconnect!');
        },
        received: (msg) => {
          console.log('Received!');
          //console.log(msg);
          console.log("通った2");
          console.log(msg);
          // 自分が送信したものかチェック
          if (!msg.user || msg.user.id != this.state.memberId) {
            console.log("通った3");
            if (msg.type === 'message') {
              console.log("通った4")
              //if (msg.created_at === msg.updated_at) {
              // 新規メッセージ
              let message = this.formatGiftedChatMessage(msg);
              this.setState((prevState) => ({
                messages: GiftedChat.append(
                  prevState.messages,
                  [message],
                  true
                ),
              }));
              // } else {
              //   // メッセージ更新
              //   const newMessages = this.state.messages.map((message) => {
              //     if (msg.id === message._id) {
              //       return {
              //         ...message,
              //         updatedAt: msg.updated_at,
              //         instruction: {
              //           id: msg.instruction_id,
              //           statusId: msg.instruction_status_id
              //         }
              //       };
              //     }
              //     return message; // 何も変更しない
              //   });
              //   this.setState({ messages: newMessages });
              //}
              this.patchAccessStatus();
            } else {
              console.log("通った5")
              console.log(this.state)

              if (this.state.memberId != msg.user_id) {
                console.log("通った6")
                // メッセージ更新（既読処理）
                const newMessages = this.state.messages.map((message) => {
                  console.log("---")
                  console.log(message)
                  let latestDatetime = moment(msg.latest_datetime).format(
                    'YYYY-MM-DD hh:mm:ss'
                  );
                  let createdAt = moment(message.createdAt).format(
                    'YYYY-MM-DD hh:mm:ss'
                  );
                  if (
                    this.state.memberId == message.user._id &&
                    !message.read ||
                    message.read == "1"
                    //moment(latestDatetime).isSameOrAfter(moment(createdAt))
                  ) {
                    return {
                      ...message,
                      read: 1,
                    };
                  }
                  return message; // 何も変更しない
                });
                this.setState({ messages: newMessages });
              }else{
                console.log("過去メッセージあるよ")
                const logMessages = this.state.messages.map((message) => {
                  if (message.read && message.read == "1") {
                    console.log("既読が必要")
                    return {
                      ...message,
                      read: 1,
                    };
                  }
                  return message;
                });
                this.setState({ messages: logMessages });
              }
            }
          }
        },
      }
    );
    console.log('==================== Chat.createSocket end.');
  }

  closeSocket() {
    console.log('==================== Chat.closeSocket start.');
    //console.log(this.cable.subscriptions) // "0"
    if (this.channel) {
      console.log('unsubscribe!');
      this.context.cable.subscriptions.remove(this.channel);
    }
    //console.log(this.cable.subscriptions) // "0"
    //console.log(this.channel.consumer.subscriptions) // "1"
    console.log('==================== Chat.closeSocket end.');
  }

  /////////////////////////////////////////////////////////////////////////
  // DB Access
  /////////////////////////////////////////////////////////////////////////
  patchAccessStatus() {
    console.log('==================== Chat.patchAccessStatus start.');
    //const latestDateTime = new Date().toISOString();
    let model = {
      chats_has_user: {
        chat_id: this.props.navigation.state.params.chat_id,
        user_id: this.context.user.id,
        //latest_datetime: latestDateTime.toLocaleString() // 端末毎に時刻が異なるため、サーバー側で生成する
      },
    };

    axios
      // .patch(Constants.manifest.extra.apiUrl + '/api/chats/status', model, {
      .post(Constants.manifest.extra.apiUrl + '/api/chat_read', model, {
        params: {
          chat_id: this.props.navigation.state.params.chat_id,
          user_id: this.context.user.id
        },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== Chat.patchAccessStatus end.');
  }

  getMessages() {
    console.log('==================== Chat.getMessages start.');
    console.log('limit :' + this.state.limit);
    console.log('offset:' + this.state.offset);
    axios
      // .get(Constants.manifest.extra.apiUrl + '/api/messages', {
       .get(Constants.manifest.extra.apiUrl + '/api/messages_list', {
        params: {
          chat_id: this.props.navigation.state.params.chat_id,
          user_id: this.context.user.id,
          limit: this.state.limit,
          offset: this.state.offset,
        },
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response.data);
        let data = response.data;
        //console.log(data);
        //this.setState({ messages: response.data });
        let messages = [];
        for (let i = 0; i < data.length; i++) {
          let message = this.formatGiftedChatMessage(data[i]);
          messages.push(message);
        }
        if (this.state.offset === 0) {
          // 初回取得
          this.setState({
            messages: messages,
            offset: this.state.offset + this.state.limit,
          });
        } else {
          // 以降ページング取得
          this.setState((previousState) => ({
            messages: GiftedChat.prepend(
              previousState.messages,
              messages,
              true
            ),
            isLoadingEarlier: false,
            offset: this.state.offset + this.state.limit,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== Chat.getMessages end.');
  }

  postMessage(messages) {
    console.log('==================== Chat.postMessage start.');
    
    if (messages.length <= 0) {
      return;
    }
    let message = messages[0];

    // 画像が選択されていた場合
    if (this.state.selectedImg) {
      message.image = this.state.selectedImg;
      this.state.selectedImg = null;
    }

    var formData = new FormData();
    formData.append('send_id', message._id); // 後に正式なメッセージIDに更新するため、現状のメッセージIDを送る
    formData.append(
      'message[chat_id]',
      this.props.navigation.state.params.chat_id
    );
    formData.append('message[user_id]', message.user._id);
    if (message.text) {
      formData.append('message[message]', message.text);
    }
    //formData.append("message[created_at]", message.createdAt.toLocaleString());
    //formData.append("message[updated_at]", message.createdAt.toLocaleString());
    if (message.instruction && message.instruction.id) {
      formData.append('message[instruction_id]', message.instruction.id);
    }
    if (message.instruction_status && message.instruction_status.id) {
      formData.append(
        'message[instruction_status_id]',
        message.instruction_status.id
      );
    }
    if (message.message_id) {
      formData.append('message[message_id]', message.message_id);
    }
    if (message.image) {
      const postImgOptions = ImageUtils.getPostImageOptions(
        message.image,
        formData.get('send_id')
      );
      if (Platform.OS == 'web') {
        let blobImage = ImageUtils.base64toBlob(
          message.image,
          postImgOptions.fileType
        );
        formData.append('message[picture]', blobImage, postImgOptions.fileName);
      } else {
        formData.append('message[picture]', {
          uri: message.image,
          type: postImgOptions.fileType,
          name: postImgOptions.fileName,
        });
      }
    }
    //console.log(formData);

    // axios.postを使用してファイルデータを含むFormDataを送るとAndroidだけエラーになる
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      var READYSTATE_COMPLETED = 4; // readyStateの値
      if (xhr.readyState == READYSTATE_COMPLETED) {
        //console.log(xhr);
        var HTTP_STATUS_OK = 200; // HTTPステータス コード
        if (xhr.status == HTTP_STATUS_OK) {
          // サーバから受信したレスポンスを表示する
          //console.log( xhr.response );
          //const editMessages = messages.map(msg => ({
          //  ...msg,
          //  _id: xhr.response.id,
          //  updatedAt: message.createdAt
          //}));
          //this.setState(previousState => ({
          //  messages: GiftedChat.append(previousState.messages, editMessages, true)
          //})
          let resMessage = xhr.response;
          const editMessages = this.state.messages.map((msg) => {
            if (resMessage.send_id === msg._id) {
              return {
                ...msg,
                _id: resMessage.id, // 正式なIDに更新
                createdAt: resMessage.created_at,
                updatedAt: resMessage.updated_at,
              };
            }
            return msg; // 何も変更しない
          });
          this.setState({ messages: editMessages });
        } else {
          console.error(xhr.status);
        }
      }
    };
    xhr.responseType = 'json';
    xhr.open('POST', Constants.manifest.extra.apiUrl + '/api/messages');
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.context.user.token);
    xhr.send(formData);

    console.log('==================== Chat.postMessage end.');
  }

  /*
  patchInstructionStatus(message_id, status) {
    console.log("==================== Chat.patchInstructionStatus start.");
    let model = {
      message: {
        instruction_status: status
      }
    };

    axios.patch(Constants.manifest.extra.apiUrl + "/api/messages/" + message_id, model, {
      headers: { 'Authorization': 'Bearer ' + this.context.user.token }
    })
    .then((response) => {
      //console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
    console.log("==================== Chat.patchInstructionStatus end.");
  }
*/

  /////////////////////////////////////////////////////////////////////////
  // Private Funtion
  /////////////////////////////////////////////////////////////////////////
  formatGiftedChatMessage(msg) {
    let userId = 0;
    let userName = Constants.manifest.name;
    let userAvatarUrl =
      Constants.manifest.extra.apiUrl + '/images/default_app.png';
    let imageUrl = null;
    let parentId = null;
    let parentCreatedAt = null;
    let instructionId = null;
    let instructionName = null;
    let statusId = null;
    let statusName = null;
    let createdAt = '2022-01-01 00:00:00';

    if (msg.user) {
      userId = msg.user.id;
      userName = msg.user.name;
      if (msg.user.avatar && msg.user.avatar.url) {
        userAvatarUrl = Constants.manifest.extra.apiUrl + msg.user.avatar.url;
      } else {
        // デフォルトユーザーアバター
        userAvatarUrl =
          Constants.manifest.extra.apiUrl + '/images/default_user.png';
      }
      createdAt = msg.created_at;
    }
    if (msg.picture && msg.picture.url) {
      imageUrl = Constants.manifest.extra.apiUrl + msg.picture.url;
    }
    if (msg.parent && msg.parent.id) {
      parentId = msg.parent.id;
      parentCreatedAt = msg.parent.created_at;
    }
    if (msg.instruction && msg.instruction.id) {
      instructionId = msg.instruction.id;
      instructionName = msg.instruction.name;
    }
    if (msg.instruction_status && msg.instruction_status.id) {
      statusId = msg.instruction_status.id;
      statusName = msg.instruction_status.name;
    }

    let message = {
      _id: msg.id,
      text: msg.message,
      createdAt: createdAt,
      image: imageUrl,
      user: {
        _id: userId,
        name: userName,
        avatar: userAvatarUrl,
      },
      parent: {
        id: parentId,
        createdAt: parentCreatedAt,
      },
      instruction: {
        id: instructionId,
        name: instructionName,
      },
      instructionStatus: {
        id: statusId,
        name: statusName,
      },
      updatedAt: msg.updated_at,
      read: msg.read_flag,
    };
    return message;
  }

  getBubbleContainerStyle(props) {
    if (props.position == 'right') {
      return {
        marginBottom: 10,
        alignItems: 'flex-end',
        maxWidth: '75%',
      };
    } else {
      return {
        marginBottom: 10,
        maxWidth: '70%',
      };
    }
  }

  getBubbleWrapperStyle(props) {
    console.log("****************************")
    console.log(props.currentMessage)
    console.log(props.currentMessage.image)
    console.log(props.currentMessage.text)

    if (props.currentMessage.image && props.currentMessage.text) {
      return {
        left: {
          backgroundColor: '#dcdcdc',
          marginRight: 5,
        },
        right: {
          backgroundColor: '#66cdaa',
          marginLeft: 5,
        },
      };
    }
    
    if (props.currentMessage.image) {
      return {
        left: {
          backgroundColor: 'transparent',
          marginRight: 5,
        },
        right: {
          backgroundColor: 'transparent',
          marginLeft: 5,
        },
      };
    } else {
      return {
        left: {
          backgroundColor: '#dcdcdc',
          marginRight: 5,
        },
        right: {
          backgroundColor: '#66cdaa',
          marginLeft: 5,
        },
      };
    }
  }

  getBubbleTextStyle() {
    return {
      left: {
        color: 'black',
      },
      right: {
        color: 'white',
      },
    };
  }

  getBubbleContainerStyle2() {
    // webでのレイアウトが崩れるのでflexは消す
    if (Platform.OS === 'web') {
      return {
        left: {},
        right: {},
      };
    } else {
      return {
        left: { flex: 0 },
        right: { flex: 0 },
      };
    }
  }
  /////////////////////////////////////////////////////////////////////////
  // View
  /////////////////////////////////////////////////////////////////////////
  createBubbleView(props) {
    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {props.position == 'right' && (
          <View style={{ justifyContent: 'flex-end' }}>
            {props.currentMessage.read === 1 ? (
              <Text style={styles.bubbleTick}>既読</Text>
            ) : null}
            <Text style={styles.bubbleTick}>
              {moment(props.currentMessage.createdAt).format('H:mm')}
            </Text>
          </View>
        )}
        <Bubble
          {...props}
          renderTime={() => null}
          wrapperStyle={this.getBubbleWrapperStyle(props)}
          textStyle={this.getBubbleTextStyle()}
          containerStyle={this.getBubbleContainerStyle2()}
        />
        {props.position == 'left' && props.currentMessage.user._id !== 0 && (
          <View style={{ justifyContent: 'flex-end' }}>
            <Text style={styles.bubbleTick}>
              {moment(props.currentMessage.createdAt).format('H:mm')}
            </Text>
          </View>
        )}
      </View>
    );
  }

  /*
  createCustomView(props) {
    if (this.state.instructionStatuses && props.currentMessage.instruction && props.currentMessage.instruction.id) {
      if (!props.currentMessage.instruction.status || props.currentMessage.instruction.status === '') {
        return (
          <View style={styles.fixToText}>
            { this.state.instructionStatuses.close.map((item, index) =>
              <Button
                key={index}
                style={styles.customViewButton}
                title={item}
                //color="#fff"
                onPress={() => this.onPressInstructionStatus(props, item)}
              />
            ) }
          </View>
        );
      } else if (props.currentMessage.parent && props.currentMessage.parent.id) {
        return (
          <View style={styles.customView}>
            <Text style={[styles.customViewText, (props.position == "left" ? styles.customViewTextLeft : styles.customViewTextRight)]}>
              {moment(props.currentMessage.parent.createdAt).format("LLL") + ' 指示'}
            </Text>
          </View>
        );
      }
    }
    return;
  }
*/

  renderBubble = (props) => (
    <View style={this.getBubbleContainerStyle(props)}>
      {props.currentMessage.user._id !== this.state.memberId && (
        <Text style={styles.bubbleName}>{props.currentMessage.user.name}</Text>
      )}
      {this.createBubbleView(props)}
    </View>
  );

  /*
  renderCustomView = (props) => (
    <View>
      {(props.currentMessage.user._id !== this.state.memberId ||
       (props.currentMessage.parent && props.currentMessage.parent.id)) &&
        this.createCustomView(props)}
    </View>
  );
*/

  // ツールバー（入力フォームや画像ボタン)
  renderInputToolbar = (props) => (
    <InputToolbar
      {...props}
      containerStyle={[
        styles.inputToolbar,
        this.state.manager
          ? styles.inputToolbarManager
          : styles.inputToolbarMember,
      ]}
      primaryStyle={{ alignItems: 'center' }}
    />
  );

  renderActions = (props) => {
    let manager = this.state.manager;
    let chat_id = this.props.navigation.state.params.chat_id
    let user_id = this.context.user.id
    let instructions = manager
      ? this.state.instructions
      : this.state.instructionStatuses;
    console.log("################")
    console.log(manager)
    console.log(instructions)
    console.log("################")
    return (
      <View style={styles.container}>
        <InstructionUtils
          {...props}
          manager={manager}
          instructions={instructions}
          onSend={(messages) => this.onActionSend(messages)}
        />
        <ImageUtils
          {...props}
          // setImage={() => this.setImageAction(image)}
          onSend={(messages) => this.onActionSend(messages)}
          setMessageToImage={(messages) => this.setMessageToImage(messages)}
        />
        <StatusUtils
          {...props}
          chatId={chat_id}
          userId={user_id}
          // statusFormInsertData={(data) => this.statusFormat(data)}
          // onSend={(messages) => this.onActionSend(messages)}
        />
      </View>
    );
  };

  renderSend = (props) => (
    <Send {...props} containerStyle={styles.sendContainer}>
      <FontAwesome5
        style={styles.sendButtonIcon}
        name="paper-plane"
        size={22}
      />
    </Send>
  );

  renderLoadEarlier = (props) => (
    <LoadEarlier
      {...props}
      label=""
      containerStyle={{ backgroundColor: 'transparent' }}
      wrapperStyle={{ backgroundColor: 'transparent' }}
      activityIndicatorColor={'gray'}
    />
  );

  renderDay = (props) => {
    // やさいっちからのメッセージは日付を表示しない
    return props.currentMessage.user._id !== 0 ? <Day {...props} /> : null;
  };




// テスト関数作成中-----------------------------------------------------------------------------

  statusFormat(data = []) {
    console.log("---statusFormat START------------------------------")
    console.log('テスト関数');
    console.log(data)

    this.statusFormInsertData(data)

    console.log("---statusFormat END------------------------------")
  }

  statusFormInsertData(data = []) {
    console.log('---statusFormInsertData START------------------------------')
    console.log(data)
    // メッセージの種類を選択済みである場合、1を設定
    // let checkNo = 1
    // this.sendCheck(checkNo)
    console.log('---statusFormInsertData END------------------------------')
  }

  setMessageToImage(messages = []) {
    console.log("アルバムが選択されて関数に飛びました")
    this.setState({ selectedImg: messages[0].image });
  }



  
// テスト関数作成中-----------------------------------------------------------------------------







  /////////////////////////////////////////////////////////////////////////
  // Event Handler
  /////////////////////////////////////////////////////////////////////////
  onActionSend(messages = []) {
    console.log('==================== Chat.onActionSend start.');
    const createdAt = new Date().toISOString();
    const messagesToUpload = messages.map((message) => ({
      _id: 'TMP-' + Math.round(Math.random() * 1000000),
      createdAt: createdAt.toLocaleString(),
      user: {
        _id: this.state.memberId,
      },
      ...message,
    }));
    console.log("???????????????????????????")
    console.log(messagesToUpload)
    this.onSend(messagesToUpload);
    console.log('==================== Chat.onActionSend end.');
  }

  onSend(messages = []) {
    console.log('==================== Chat.onSend start.');
    console.log(messages);
    this.postMessage(messages);
    this.setState((previousState) => ({
      messages: GiftedChat.append(previousState.messages, messages, true),
    }));
    Keyboard.dismiss();
    console.log('==================== Chat.onSend end.');
  }

  onLoadEarlier() {
    console.log('==================== Chat.onLoadEarlier start.');
    this.setState({ isLoadingEarlier: true }); // ローディングインジケータ表示
    this.getMessages();
    console.log('==================== Chat.onLoadEarlier end.');
  }

  onLongPress(context, message) {
    console.log("長押しされたよ")
    this.setState({ replyMessage: message });
    // 特に何も処理しない
  }



  

  shouldUpdateMessage(props, nextProps) {
    //console.log("==================== Chat.shouldUpdateMessage start.");
    let update =
      props.updatedAt !== nextProps.updatedAt || props.read !== nextProps.read;
    //console.log("==================== Chat.shouldUpdateMessage end. : " + update);
    return update;
  }

  onEndReached = () => {
    console.log('==================== Chat.onEndReached start.');
    this.onLoadEarlier();
    // this.setState({ isLoadingEarlier: true }); // ローディングインジケータ表示
    // this.getMessages();
    console.log('==================== Chat.onEndReached end.');
  };

  /*
  onPressInstructionStatus(props, item) {
    console.log("==================== Chat.onPressInstructionStatus start.");
    //console.log(props.currentMessage);
    let parent_message_id = props.currentMessage._id;
    this.patchInstructionStatus(parent_message_id, item);

    // 上記の作業指示ステータス更新とメッセージ送信がかぶるとダメなんので、少しずらす
    setTimeout(() => {
      let instruction = null;
      this.state.instructions.map((inst) => {
        if (props.currentMessage.instruction.id === inst.id) {
          instruction = inst;
          return;
        }
      });
      if (instruction) {
        let message = {
          text: '【作業報告】\n ' + instruction.instruction + ' の' + item + 'しました。',
          instruction: {
            id: instruction.id,
            status: item
          },
          message_id: parent_message_id,
          parent: {
            id: parent_message_id,
            createdAt: props.currentMessage.createdAt
          }
        };
        this.onActionSend([message]); // 送信
      }
    }, 200);
    console.log("==================== Chat.onPressInstructionStatus end.");
  }
*/
  // 画像選択を取り消すメソッド
  cancelImageSelection = () => {
    this.setState({ selectedImg: null });
  };

  render() {
    return (
      <View style={{ flex: 1}}>
        {this.state.selectedImg && (
          <View style={styles.previewContainer}>
            <Text>選択された画像</Text>
            <Image source={{ uri: this.state.selectedImg }} style={styles.previewImage} />
            <FontAwesome5 
              onPress={this.cancelImageSelection} 
              style={styles.cancelIcon}
              size={22}
            >
              ×
            </FontAwesome5>
          </View>
        )}
        <GiftedChat
          messages={this.state.messages}
          placeholder="メッセージを入力"
          locale="ja"
          //dateFormat={'YYYY/MM/DD'}
          timeFormat={'H:mm'}
          user={{
            _id: this.state.memberId,
          }}
          inverted={true}
          alwaysShowSend={true} // 送信ボタンを常に表示するか
          renderAvatarOnTop={true} // アバターをメッセージの上部に表示
          showAvatarForEveryMessage={false} // 同日に同じ人から連続で来たメッセージに対してアバターを全てつけるか
          keyboardShouldPersistTaps={'never'} // 入力エリア欄外のタップでキーボードを閉じるか（'never'=閉じる、'always'=閉じない）
          isCustomViewBottom={true} // カスタムビューをテキストの下に表示するか
          infiniteScroll={false} // スクロールで自動的にローディングを行うか Note: web非対応
          loadEarlier={true} // 前を読み込むボタンを表示するか
          isLoadingEarlier={this.state.isLoadingEarlier} // ローディングインジケータ表示するか
          containerStyle={styles.bottomContainer}
          textInputStyle={styles.textInputContainer}
          renderBubble={this.renderBubble}
          renderSend={this.renderSend}
          renderActions={this.renderActions}
          renderInputToolbar={this.renderInputToolbar}
          //        renderCustomView={this.renderCustomView}
          renderLoadEarlier={this.renderLoadEarlier}
          renderDay={this.renderDay}
          onSend={(messages) => this.onSend(messages)}
          onLoadEarlier={() => this.onLoadEarlier()}
          onLongPress={() => this.onLongPress()}
          shouldUpdateMessage={(props, nextProps) =>
            this.shouldUpdateMessage(props, nextProps)
          }
          // FlatListのProps設定（メッセージ処理で使用）
          listViewProps={{
            removeClippedSubviews: true,
            onEndReached: this.onEndReached,
            onEndReachedThreshold: 0.3,
            initialNumToRender: this.state.offset + this.state.limit,
          }}
        />
      </View>
      
    );
  }
}
Chat.contextType = AuthContext;
//Chat.contextType = {
//  cable: PropTypes.object.isRequired
//};

const styles = StyleSheet.create({
  previewContainer: {
    marginVertical: 10,
    alignItems: 'center',
  },
  previewImage: {
    width: 150,
    height: 150,
    borderRadius: 10,
  },
  bubbleName: {
    color: 'gray',
    fontSize: 10,
  },
  bubbleTick: {
    color: 'gray',
    fontSize: 9,
  },
  bottomContainer: {
    backgroundColor: 'hsl(0, 0%, 90%)',
  },
  sendContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginRight: 10,
  },
  sendButtonIcon: {
    //color: '#4fa9ff'
    color: 'white',
  },
  textInputContainer: {
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 10,
    paddingLeft: 5,
    paddingTop: 7,
    paddingBottom: 7,
    marginTop: 2,
    marginRight: 10,
    // marginLeft: 40,
    backgroundColor: 'white',
  },
  // ボタンアイコンのエリア
  container: {
    //width: 26,//26,
    //height: 26,
    //marginLeft: 10,
    //marginBottom: 10,
    width: 100,
    flexDirection: 'row',
  },

  fixToText: {
    //width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 10,
  },
  customView: {
    marginBottom: 5,
  },
  customViewText: {
    fontSize: 9,
    textAlign: 'right',
    marginRight: 5,
    color: 'blue',
  },
  customViewButton: {
    width: 100,
    //flex: 1,
    //marginRight: 20
  },
  customViewTextLeft: {
    color: 'black',
  },
  customViewTextRight: {
    color: 'white',
  },
  inputToolbar: {
    padding: 6,
  },
  inputToolbarManager: {
    backgroundColor: '#222B45',
  },
  inputToolbarMember: {
    backgroundColor: '#224f45',
  },
  cancelIcon: {
    // position: 'absolute',
    // top: 1,
    // right: 1,
    color: 'red',
  },
});
