import React from 'react';
import {
  View,
  Text,
  Button,
  TextInput,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Platform,
} from 'react-native';
import axios from 'axios';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

import { AuthContext, useAuthContext } from '../utils/ContextUtils';
import Constants from 'expo-constants';
import * as SecureStoreWeb from '../utils/SecureStoreWeb';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class Login extends React.Component {
  constructor(props) {
    console.log('==================== Login.constructor start.');
    super(props);
    this.state = { email: '', password: '', loading: false, failed: false };
    console.log('==================== Login.constructor end.');
  }

  async componentDidMount() {
    console.log('==================== Login.componentDidMount start.');
    if (
      this.props.navigation.state &&
      this.props.navigation.state.params &&
      this.props.navigation.state.params.logout
    ) {
      this.deleteUser();
      await SecureStoreWeb.deleteItemAsyncWeb('user-info');
    } else {
      let strUserInfo = await SecureStoreWeb.getItemAsyncWeb('user-info');
      if (strUserInfo) {
        let userInfo = JSON.parse(strUserInfo);
        if (userInfo.token) {
          //let accessToken = await SecureStore.getItemAsync('access-token');
          //let client = await SecureStore.getItemAsync('client');
          //let uid = await SecureStore.getItemAsync('uid');
          //this.context.header = {
          //  accessToken: accessToken,
          //  client: client,
          //  uid: uid
          //};

          //let strUserInfo = await SecureStore.getItemAsync('user-info');
          //let userInfo = JSON.parse(strUserInfo);
          //this.context.user = userInfo;
          //console.log("==================== Login -> Main:" + this.context.user.id);
          //console.log("==================== Login -> Main:" + this.context.user.role);
          //this.props.navigation.navigate('Main');
          this.getUser(userInfo);
        }
      }
    }
    console.log('==================== Login.componentDidMount end.');
  }

  deleteUser() {
    console.log('==================== Login.deleteUser start.');
    axios
      .delete(Constants.manifest.extra.apiUrl + '/api/auth/sign_out', {
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== Login.deleteUser end.');
  }

  getUser(userInfo) {
    console.log('==================== Login.getUser start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/auth/' + userInfo.id, {
        headers: { Authorization: 'Bearer ' + userInfo.token },
      })
      .then((response) => {
        console.log(response);
        this.context.user = userInfo;
        console.log(
          '==================== Login -> Main:' + this.context.user.id
        );
        console.log(
          '==================== Login -> Main:' + this.context.user.role
        );
        this.props.navigation.navigate('Main');
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
      });
    console.log('==================== Login.getUser end.');
  }

  patchUser(pushToken) {
    console.log('==================== Login.patchUser start.');

    // web取得できない
    if (pushToken == undefined) {
      return;
    }

    let model = {
      registration: {
        push_token: pushToken,
      },
    };
    console.log(model);
    axios
      .patch(
        Constants.manifest.extra.apiUrl + '/api/auth/' + this.context.user.id,
        model,
        {
          headers: { Authorization: 'Bearer ' + this.context.user.token },
        }
      )
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
      });
    console.log('==================== Login.patchUser end.');
  }

  renderLoginButton() {
    if (this.state.loading) {
      return <ActivityIndicator size="small" />;
    } else {
      {
        /*<Button title="ログイン" onPress={() => {this.onSubmit()}} />*/
      }
      return (
        <TouchableOpacity
          style={styles.containerLogin}
          onPress={() => this.onSubmit()}
        >
          <Text style={styles.textLogin}>ログイン</Text>
        </TouchableOpacity>
      );
    }
  }

  onSubmit() {
    this.setState({ loading: true });
    //console.log("onSubmit!!");
    let model = { email: this.state.email, password: this.state.password };
    //model.email = this.email
    //model.password = this.password
    axios
      .post(Constants.manifest.extra.apiUrl + '/api/auth/sign_in', model)
      .then((response) => {
        //console.log(response);
        this.setState({ loading: false });
        //let accessToken = response.headers['access-token'];
        //let client = response.headers['client'];
        //let uid = response.headers['uid'];
        //SecureStore.setItemAsync('access-token', accessToken);
        //SecureStore.setItemAsync('client', client);
        //SecureStore.setItemAsync('uid', uid);
        //this.context.header = {
        //  accessToken: accessToken,
        //  client: client,
        //  uid: uid
        //};

        let user = response.data;
        SecureStoreWeb.setItemAsyncWeb('user-info', user);
        this.context.user = user;
        console.log('USER ID:' + this.context.user.id);
        console.log('ROLE   :' + this.context.user.role);

        // Push通知用トークン取得
        this.registerForPushNotificationsAsync().then((pushToken) =>
          this.patchUser(pushToken)
        );

        this.props.navigation.navigate('Main');
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
        this.setState({ loading: false });
        this.setState({ failed: true });
      });
  }

  async registerForPushNotificationsAsync() {
    let token;
    // web は対応していない？
    if (Platform.OS === 'web') {
      return;
    }
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Push通知用のトークン取得に失敗しました。');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      // シミュレータ
      alert('Push通知は実機のみ対応です。');
      return;
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    return token;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Image
            style={styles.imageLogo}
            source={require('../assets/icon.png')}
          />
          <Text style={styles.textTitle}>{Constants.manifest.name}</Text>
        </View>
        <View style={styles.footer}>
          {this.state.failed && (
            <Text style={styles.textError}>ログインに失敗しました</Text>
          )}

          <TextInput
            style={styles.textInput}
            placeholder="メールアドレス"
            onChangeText={(email) => this.setState({ email })}
          />
          <TextInput
            style={styles.textInput}
            placeholder="パスワード"
            onChangeText={(password) => this.setState({ password })}
            secureTextEntry={true}
          />

          {this.renderLoginButton()}
          {/*<Button title="ログイン" onPress={() => {this.props.navigation.navigate('Main')}} />*/}
        </View>
      </View>
    );
  }
}
Login.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#009387',
  },
  header: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    //paddingHorizontal: 20,
    //paddingBottom: 100
    marginBottom: 30,
  },
  footer: {
    flex: 2,
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
    //marginTop: 100
  },
  imageLogo: {
    backgroundColor: '#fff',
    width: 100,
    height: 100,
    paddingTop: 100,
    borderRadius: 5,
  },
  textTitle: {
    marginTop: 10,
    fontSize: 36,
    color: '#ffffff',
  },
  textInput: {
    height: 50,
    padding: 5,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'gray',
  },
  containerLogin: {
    backgroundColor: '#009387',
    height: 50,
    justifyContent: 'center',
    borderRadius: 10,
    marginTop: 10,
  },
  textLogin: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 20,
  },
  textError: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'center',
    color: 'red',
  },
});
